body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Eina04-Bold";
  src: local("Eina04-Bold"),
    url("./fonts/Eina04/Eina04-Bold.ttf") format("trueType");
}

@font-face {
  font-family: "Eina04-SemiBold";
  src: local("Eina04-SemiBold"),
    url("./fonts/Eina04/Eina04-SemiBold.ttf") format("trueType");
}


@font-face {
  font-family: "Eina04-Regular";
  src: local("Eina04-Regular"),
    url("./fonts/Eina04/Eina04-Regular.ttf") format("trueType");
}

@font-face {
  font-family: "Eina04-RegularItalic";
  src: local("Eina04-RegularItalic"),
    url("./fonts/Eina04/Eina04-RegularItalic.ttf") format("trueType");
}


@font-face {
  font-family: "Eina04-Light";
  src: local("Eina04-Light"),
    url("./fonts/Eina04/Eina04-Light.ttf") format("trueType");
}


@font-face {
  font-family: "Eina04-LightItalic";
  src: local("Eina04-LightItalic"),
    url("./fonts/Eina04/Eina04-LightItalic.ttf") format("trueType");
}


@font-face {
  font-family: "RobotoMono-Regular";
  src: local("RobotoMono-Regular"),
    url("./fonts/RobotoMono/RobotoMono-Regular.ttf") format("trueType");
}

@font-face {
  font-family: "RobotoMono-Light";
  src: local("RobotoMono-Light"),
    url("./fonts/RobotoMono/RobotoMono-Light.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Black";
  src: local("Roboto-Black"),
    url("./fonts/Roboto/Roboto-Black.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: local("Roboto-BlackItalic"),
    url("./fonts/Roboto/Roboto-BlackItalic.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url("./fonts/Roboto/Roboto-Bold.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-BoldItalic";
  src: local("Roboto-BoldItalic"),
    url("./fonts/Roboto/Roboto-BoldItalic.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Italic";
  src: local("Roboto-Italic"),
    url("./fonts/Roboto/Roboto-Italic.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-LightItalic";
  src: local("Roboto-LightItalic"),
    url("./fonts/Roboto/Roboto-LightItalic.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url("./fonts/Roboto/Roboto-Medium.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-MediumItalic";
  src: local("Roboto-MediumItalic"),
    url("./fonts/Roboto/Roboto-MediumItalic.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-Thin";
  src: local("Roboto-Thin"),
    url("./fonts/Roboto/Roboto-Thin.ttf") format("trueType");
}

@font-face {
  font-family: "Roboto-ThinItalic";
  src: local("Roboto-ThinItalic"),
    url("./fonts/Roboto/Roboto-ThinItalic.ttf") format("trueType");
}



@font-face {
  font-family: "Plus-Jakarta-Sans";
  src: local("Plus-Jakarta-Sans"),
    url("./fonts/JakartaSans/PlusJakartaSans-VariableFont_wght.ttf") format("trueType");
}

@font-face {
  font-family: "Plus-Jakarta-Sans-Bold";
  src: local("Plus-Jakarta-Sans-Bold"),
    url("./fonts/JakartaSans/PlusJakartaSans-Bold.ttf") format("trueType");
}

@font-face {
  font-family: "Plus-Jakarta-Sans-SemiBold";
  src: local("Plus-Jakarta-Sans-SemiBold"),
    url("./fonts/JakartaSans/PlusJakartaSans-SemiBold.ttf") format("trueType");
}

@font-face {
  font-family: "Plus-Jakarta-Sans-RegularItalic";
  src: local("Plus-Jakarta-Sans-RegularItalic"),
    url("./fonts/JakartaSans/PlusJakartaSans-Italic.ttf") format("trueType");
}


@font-face {
  font-family: "Plus-Jakarta-Sans-Light";
  src: local("Plus-Jakarta-Sans-Light"),
    url("./fonts/JakartaSans/PlusJakartaSans-Light.ttf") format("trueType");
}


@font-face {
  font-family: "Plus-Jakarta-Sans-LightItalic";
  src: local("Plus-Jakarta-Sans-LightItalic"),
    url("./fonts/JakartaSans/PlusJakartaSans-LightItalic.ttf") format("trueType");
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background-color: rgb(228, 228, 228);
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(111, 111, 111);
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
}

.deliveryCardGrid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.deliveryCardGrid::-webkit-scrollbar {
  display: none;
  margin: 14px;
}

.deliveryCardMenuItems {
  scroll-behavior: smooth;
}

.deliveryCardMenuItems::-webkit-scrollbar-track {
  background-color: transparent;

}

.deliveryCardMenuItems::-webkit-scrollbar-thumb {
  background-color: #C6C6C6;
}

.deliveryCardMenuItems::-webkit-scrollbar-thumb:hover {
  background-color: #C6C6C6;
}

.icons {
  animation: createBox .25s;
}

@keyframes createBox {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* Embla Carousel */
.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  position: relative;
  display: grid;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}

.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}