/* SnackbarStyles.css */

@-webkit-keyframes progressKeyframe {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes progressKeyframe {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.snackbarWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 65px;
    z-index: 13000;
    /* box-shadow: 29px 29px 29px 20px rgba(0, 0, 0, 0.15); */
}

.snackbarContainer {
    padding-top: 4px;
    padding-left: 10%;
    padding-right: 10%;
}

@media (min-width: 960px) {
    .snackbarContainer {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (min-width: 1280px) {
    .snackbarContainer {
        padding-left: 15%;
        padding-right: 15%;
    }
}

.snackbarIconContainer svg {
    width: 30px;
    height: 30px;
    color: var(--color-neutral-0);
    margin-top: 6px;
}

.meter {
    height: 8px;
    background: var(--color-gradient-100);
    overflow: hidden;
    animation: progressKeyframe linear;
    -webkit-animation: progressKeyframe linear;
}
